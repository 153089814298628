import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  PageBreak,
  Table,
  TableCell,
  TableRow,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";

function PurchaseEdit() {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  let { id } = useParams();

  const [formState, setFormState] = useState({
      releaseName: "",
      numberOfSongs: 1,
      purchasePrice: 1000,
      // futureSongsIncluded: false,
      // futureSongsNumber: 1,
      // // futures: [],
      // futureOptionNumber: 1,
      // futureOptionNumberInWords: "one",
      // futureOptionPeriod: "year",
      // futureAdvanceValue: 1000,
      // futureAdvanceValueInWords: "one thousand",
      // futureMarketingValue: 3000,
      // futureMarketingValueInWords: "three thousand",
      // publishingIncluded: false,
      numberOfArtists: 1,
      songs: [{ name: "" }],
      artists: [
        {
          stageName: "",
          legalName: "",
          address: "",
          dob: new Date(),
          // publisher: "N/A", // Default to 'N/A'
          // ipiNumber: "N/A",
          // publisherName: "N/A",
          parentGuardianName: "",
        },
      ],
      // advanceIncluded: false,
      // marketingIncluded: false,
      agreementDate: new Date(),
      // netProceeds: {
      //   percentage: 55,
      //   percentageInWords: "Fifty-five",
      // },
      // termLength: {
      //   lifeOfCopyright: false,
      //   numberOfYears: 2,
      //   numberOfYearsInWords: "Two",
      // },
      // advanceAmount: {
      //   value: 1000,
      //   valueInWords: "One thousand",
      // },
      // marketingBudget: {
      //   value: 3000,
      //   valueInWords: "Three thousand",
      // },
      //actual text
  
      // titleText: "",
      // dateText: "",
      // artistsText: "",
      // artistsInPreambleText: "",
    });

  useEffect(() => {
    async function getContractData(){
        const response = await axios.get(`/api/purchasecontracts/${id}`, {
          headers: {
            // Include other headers as needed, for example, an authorization header:
            Authorization: `Bearer ${user.token}`,
          },
        });
        console.log(response);
        setFormState({
            ...response.data,
            agreementDate: new Date(
              response.data.agreementDate
            ), // Convert agreementDate to Date object
            artists: response.data.artists.map((artist) => ({
              ...artist,
              dob: artist.dob ? new Date(artist.dob) : null, // Convert dob to Date object, handling potential nulls
            })),
          });
      }
      getContractData();
  }, [])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (
      name === "futureSongsIncluded" ||
      name === "publishingIncluded" ||
      name === "advanceIncluded" ||
      name === "marketingIncluded"
    ) {
      setFormState((prev) => ({ ...prev, [name]: checked }));
      // if (name === "futureSongsIncluded" && e.target.checked) {
      //   const futures = Array(1)
      //     .fill()
      //     .map((_, i) => ({
      //       optionNumber: 1,
      //       optionNumberInWords: "",
      //       optionPeriod: "years",
      //       advanceValue: "",
      //       advanceValueInWords: "",
      //       marketingValue: "",
      //       marketingValueInWords: "",
      //     }));
      //   setFormState((prev) => ({
      //     ...prev,
      //     futureSongsNumber: 1,
      //     futures,
      //   }));
      // } else if (name === "futureSongsIncluded" && !e.target.checked) {
      //   const futures = Array(0);
      //   setFormState((prev) => ({
      //     ...prev,
      //     futureSongsNumber: 0,
      //     futures,
      //   }));
      // }
    } else {
      setFormState((prev) => ({
        ...prev,
        [name]: type === "number" ? parseInt(value) : value,
      }));
    }
  };

  const handleSongNameChange = (index, value) => {
    const updatedSongs = [...formState.songs];
    updatedSongs[index].name = value;
    setFormState((prev) => ({ ...prev, songs: updatedSongs }));
  };

  const handleArtistChange = (index, field, value) => {
    const updatedArtists = formState.artists.map((artist, idx) => {
      if (field == "publisher" && value == "other" && idx === index) {
        return {
          ...artist,
          publisher: value,
          ipiNumber: "",
          publisherName: "",
        };
      } else if (field == "publisher" && value == "N/A" && idx === index) {
        return {
          ...artist,
          publisher: value,
          ipiNumber: "N/A",
          publisherName: "N/A",
        };
      }
      if (
        idx === index &&
        field == "dob" &&
        new Date() <
          new Date(
            formState.artists[index].dob.getFullYear() + 18,
            formState.artists[index].dob.getMonth(),
            formState.artists[index].dob.getDate()
          )
      ) {
        return { ...artist, dob: value, parentGuardianName: "" };
      } else if (idx === index && field == "dob") {
        return { ...artist, dob: value, parentGuardianName: "N/A" };
      }
      if (idx === index) {
        return { ...artist, [field]: value };
      }
      return artist;
    });
    setFormState({ ...formState, artists: updatedArtists });
  };

  // const handleAddArtist = () => {
  //   const newArtist = {
  //     stageName: "",
  //     legalName: "",
  //     address: "",
  //     dob: new Date(),
  //     publisher: "N/A",
  //     ipiNumber: "",
  //     parentGuardianName: "",
  //   };
  //   setFormState({ ...formState, artists: [...formState.artists, newArtist] });
  // };

  const handleDateChange = (date) => {
    setFormState((prev) => ({ ...prev, agreementDate: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submit action

    try {
      const response = await axios.put(`/api/purchasecontracts/${id}`, formState, {
        headers: {
          // Include other headers as needed, for example, an authorization header:
          Authorization: `Bearer ${user.token}`,
        },
      });

      console.log(response.data); // Handle the response data as needed
      // Optionally, you might want to redirect the user or clear the form here
      navigate("/");
    } catch (error) {
      console.error(
        "There was an error!",
        error.response ? error.response.data : error.message
      );
      // Handle errors, for example, by displaying a message to the user
    }
  };

  const addSongFields = (e) => {
    const value = e.target.value;
    const numberOfSongs = value === "" ? "" : parseInt(value, 10);
  
    // Only update the state if the input is not empty and is a valid number
    if (numberOfSongs === "" || !isNaN(numberOfSongs)) {
      const songs = numberOfSongs === "" ? [] : Array(numberOfSongs).fill().map((_, i) => ({ name: "" }));
      setFormState((prev) => ({ ...prev, numberOfSongs, songs }));
    }
  };
  
  
  

  const addArtistFields = (e) => {
    const value = e.target.value;
    const numberOfArtists = value === "" ? "" : parseInt(value, 10);
  
    // Only update the state if the input is not empty and is a valid number
    if (numberOfArtists === "" || !isNaN(numberOfArtists)) {
      const artists = numberOfArtists === "" ? [] : Array(numberOfArtists)
        .fill()
        .map((_, i) => ({
          stageName: "",
          legalName: "",
          address: "",
          dob: new Date(),
          parentGuardianName: "N/A",
        }));
  
      setFormState((prev) => ({ ...prev, numberOfArtists, artists }));
    }
  };

//   const addFuturesFields = (e) => {
//     const numberOfFutures = parseInt(e.target.value);
//     const futures = Array(numberOfFutures)
//       .fill()
//       .map((_, i) => ({
//         optionNumber: 1,
//         optionNumberInWords: "One",
//         optionPeriod: "year",
//         advanceValue: 1000,
//         advanceValueInWords: "One thousand",
//         marketingValue: 3000,
//         marketingValueInWords: "Three thousand",
//       }));
//     setFormState((prev) => ({
//       ...prev,
//       futureSongsNumber: numberOfFutures,
//       futures,
//     }));
//   };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: "600px",
          margin: "auto",
          fontFamily: "Arial, sans-serif",
        }}
      >
        {/* <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Name of Release:
            <input
              type="text"
              name="releaseName"
              value={formState.releaseName}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div> */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Number of Songs:
            <input
            //   type="number"
              name="numberOfSongs"
            //   min="1"
              max="100"
              value={formState.numberOfSongs}
              onChange={addSongFields}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Purchase Price
            <input
            //   type="number"
              name="purchasePrice"
            //   min="1"
              max="100"
              value={formState.purchasePrice}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Date of Agreement:
          </label>
          <DatePicker
            selected={formState.agreementDate}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            popperPlacement="bottom"
            wrapperClassName="datePicker"
            className="form-control"
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginTop: "5px",
            }}
          />
        </div>
        <div>
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Number of Artists:
              <input
                type="number"
                name="numberOfArtists"
                min="1"
                max="10"
                value={formState.numberOfArtists}
                onChange={addArtistFields}
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </label>
          </div>
        </div>
        {formState.songs.map((song, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Song {index + 1} Name:
              <input
                type="text"
                value={song.name}
                onChange={(e) => handleSongNameChange(index, e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </label>
          </div>
        ))}
        {formState.artists.map((artist, index) => (
          <div
            key={index}
            style={{
              marginBottom: "30px",
              backgroundColor: "#dbdbdb",
              padding: "20px",
              borderRadius: "10px",
              paddingBottom: "40px",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Artist {index + 1}'s Stage Name:
              </label>
              <input
                type="text"
                value={artist.stageName}
                onChange={(e) =>
                  handleArtistChange(index, "stageName", e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Artist {index + 1}'s Legal Name:
              </label>
              <input
                type="text"
                value={artist.legalName}
                onChange={(e) =>
                  handleArtistChange(index, "legalName", e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Artist {index + 1}'s Address:
              </label>
              <input
                type="text"
                value={artist.address}
                onChange={(e) =>
                  handleArtistChange(index, "address", e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Artist {index + 1}'s Date of Birth:
              </label>
              <DatePicker
                selected={artist.dob}
                onChange={(date) => handleArtistChange(index, "dob", date)}
                dateFormat="MM/dd/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperPlacement="bottom"
                wrapperClassName="datePicker"
                className="form-control"
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginTop: "5px",
                }}
              />
            </div>

            {/* <div style={{ marginBottom: "20px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Artist {index + 1}'s Publisher:
              </label>
              <select
                value={artist.publisher}
                onChange={(e) =>
                  handleArtistChange(index, "publisher", e.target.value)
                }
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                <option value="N/A">N/A</option>
                <option value="other">
                  Other - Provide Name and IPI Number
                </option>
              </select>
            </div> */}

            {/* {artist.publisher === "other" && (
              <>
                <div style={{ marginBottom: "20px" }}>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Publisher Name:
                  </label>
                  <input
                    type="text"
                    value={artist.publisherName}
                    onChange={(e) =>
                      handleArtistChange(index, "publisherName", e.target.value)
                    }
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    IPI Number:
                  </label>
                  <input
                    type="text"
                    value={artist.ipiNumber}
                    onChange={(e) =>
                      handleArtistChange(index, "ipiNumber", e.target.value)
                    }
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </>
            )} */}

            {/* Conditional rendering based on age */}
            {artist.dob &&
              new Date() <
                new Date(
                  artist.dob.getFullYear() + 18,
                  artist.dob.getMonth(),
                  artist.dob.getDate()
                ) && (
                <div style={{ marginBottom: "20px" }}>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Artist {index + 1}'s Parent/Guardian's Full Legal Name:
                  </label>
                  <input
                    type="text"
                    value={artist.parentGuardianName}
                    onChange={(e) =>
                      handleArtistChange(
                        index,
                        "parentGuardianName",
                        e.target.value
                      )
                    }
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              )}
          </div>
        ))}

        <button
          type="submit"
          style={{ padding: "10px", cursor: "pointer", marginBottom: "20px" }}
        >
          Save
        </button>
      </form>
      {/* <textarea
        value={formState.titleText}
        style={{ width: "70%", height: "50px" }}
      />
      <textarea
        value={formState.dateText}
        style={{ width: "70%", height: "50px" }}
      />
      <textarea
        value={formState.artistsText}
        style={{ width: "70%", height: "200px" }}
      />
      <textarea
        readOnly={true}
        style={{
          width: "70%",
          height: "150px",
          background: "none",
          border: "none",
        }}
        value={`\n&\n\nThe “LABEL”:\nThe System Music LLC \n3500 S DuPont Highway, Dover, DE 19901\n\n\nThe System Music LLC (“Label”) on behalf of its administered channels “The System” is delighted to have this opportunity to work with\n`}
      ></textarea>
      <textarea
        value={formState.artistsInPreambleText}
        style={{ width: "70%", height: "80px" }}
      />
      <textarea
        readOnly={true}
        style={{
          width: "70%",
          height: "280px",
          background: "none",
          border: "none",
        }}
        value={`collectively (“Artist”) to distribute and exploit certain sound recordings and audiovisual works owned or controlled by Artist as further detailed herein.\n\nThe agreement is conditional upon Artist controlling all right, title and interest in and to the recordings of the musical composition (the “Composition”) embodied as audio-only or audiovisual recordings and listed in Schedule ‘A’ hereto (the “Master”) for the Territory (as defined below), including without limitation all copyrights, free and clear of any claims, encumbrances or rights of any other person.\n\nIn consideration for the respective covenants, agreements, representations, warranties and indemnities of the parties contained herein and for other good and valuable consideration (the receipt and sufficiency of which are hereby acknowledged by each party), the parties agree as follows:\n\n1. TERRITORY\n1.1 As used herein, the “Territory” shall mean the Universe.\n2. LICENSE PERIOD\n2.1 “License Period” shall mean the period commencing on the date hereof and expiring on the later of:
        `}
      /> */}
    </div>
  );
}

export default PurchaseEdit;
